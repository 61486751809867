/*
 * @Descripttion: []
 * @Author: Iseven Monkey <iswoter@gmail.com>
 * @Date: 2021-08-08 08:09:14
 * @LastEditors: Iseven Monkey <iswoter@gmail.com>
 * @LastEditTime: 2021-10-20 11:47:45
 * @FilePath: /site/src/views/index/api/modules/ucenter/Video.js
 */

import { Axios } from "../../../plugins/AxiosPlugin";
export const lists = (params) =>
  Axios.get("/api/front/user/video", {
    params: params,
  });

export const detail = (params) =>
  Axios.get("/api/front/user/video/detail", {
    params: params,
  });

export const save = (params) =>
  Axios.post("/api/front/user/video/save", params);

export const del = (params) => Axios.post("/api/front/user/video/del", params);

export const favorite = (params) =>
  Axios.get("/api/front/user/video/favorite", {
    params: params,
  });
