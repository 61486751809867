/*
 * @Descripttion: []
 * @Author: Iseven Monkey <iswoter@gmail.com>
 * @Date: 2021-08-08 08:09:14
 * @LastEditors: Iseven Monkey <iswoter@gmail.com>
 * @LastEditTime: 2021-10-20 14:57:29
 * @FilePath: /site/src/views/index/api/modules/ucenter/User.js
 */

import { Axios } from "../../../plugins/AxiosPlugin";

export const saveUserInfo = (params) =>
  Axios.post("/api/front/user/user/saveUserInfo", params);

export const avatar = (params) =>
  Axios.post("/api/front/user/user/avatar", params);
