/*
 * @Descripttion: []
 * @Author: Iseven Monkey <iswoter@gmail.com>
 * @Date: 2021-05-21 23:52:05
 * @LastEditors: Iseven Monkey <iswoter@gmail.com>
 * @LastEditTime: 2021-11-08 17:17:16
 * @FilePath: /site/src/views/index/router/index.js
 */
import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Layout from "../views/layout/index.vue";
Vue.use(VueRouter);

export const routes = [
  {
    path: "/bigdata",
    name: "bigdata",
    component: () =>
      import ("../views/website/index.vue"),
    hidden: false,
    meta: {
      title: "bigdata",
    },
  },
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
    hidden: false,
    meta: {
      title: "首页",
      label: "home",
    },
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("../views/Content.vue"),
    hidden: false,
    meta: {
      title: "联系我们",
      label: "contact",
    },
  },
  {
    path: "/report",
    name: "Report",
    component: () => import("../views/Content.vue"),
    hidden: false,
    meta: {
      title: "反馈信息",
      label: "report",
    },
  },

  {
    path: "/help",
    name: "help",
    component: () => import("../views/help/List.vue"),
    hidden: false,
    meta: {
      title: "帮助",
      icon: "help",
      category_id: 3,
    },
  },
  {
    path: "/help/detail/:id",
    name: "helpDetail",
    component: () => import("../views/help/Detail.vue"),
    hidden: true,
    meta: {
      title: "帮助详情",
    },
  },
  {
    path: "/video/detail/:id",
    name: "VideoDetail",
    component: () => import("../views/video/Detail.vue"),
    hidden: true,
    meta: {
      title: "视频详情",
      hasMobile: true, //有手机版
    },
  },
  {
    path: "/race",
    name: "race",
    component: () => import("../views/race/List.vue"),
    hidden: false,
    meta: {
      title: "大赛",
      icon: "race",
      category_id: 3,
      hasMobile: true, //有手机版
    },
  },
  {
    path: "/race/detail/:id",
    name: "raceDetail",
    component: () => import("../views/race/Detail.vue"),
    hidden: true,
    meta: {
      title: "大赛详情",
      hasMobile: true, //有手机版
    },
  },
  {
    path: "/org",
    name: "org",
    component: () => import("../views/org/List.vue"),
    hidden: false,
    meta: {
      title: "机构",
      icon: "org",
      hasMobile: true, //有手机版
    },
  },
  {
    path: "/org/detail/:id",
    name: "orgDetail",
    component: () => import("../views/org/Detail.vue"),
    hidden: true,

    meta: {
      title: "机构详情",
      hasMobile: true, //有手机版
    },
  },
  {
    path: "/vlog/:id",
    name: "Vlog",
    component: () => import("../views/vlog/index.vue"),
    hidden: true,

    meta: {
      title: "Vlog",
      hasMobile: true, //有手机版
    },
  },
  {
    path: "/ucenter",
    name: "Ucenter",
    component: () => import("../views/ucenter"),
    hidden: true,
    hasMobile: true,
    meta: {
      title: "个人中心",
      icon: "ucenter",
    },
    children: [
      {
        path: "/ucenter/publish",
        name: "videoPublish",
        component: () => import("../views/ucenter/video/Publish.vue"),
        hidden: true,
        meta: {
          title: "发布视频",
        },
      },
      {
        path: "/ucenter/video",
        name: "ucenterVideo",
        component: () => import("../views/ucenter/video/List.vue"),
        hidden: true,
        meta: {
          title: "作品管理",
        },
      },
      {
        path: "/ucenter/comment",
        name: "ucenterComment",
        component: () => import("../views/ucenter/comment/List.vue"),
        hidden: true,
        meta: {
          title: "评论管理",
        },
      },
      {
        path: "/ucenter/fav",
        name: "ucenterFav",
        component: () => import("../views/ucenter/video/Favorite.vue"),
        hidden: true,
        meta: {
          title: "我的收藏",
        },
      },
      {
        path: "/ucenter/follow",
        name: "ucenterFollow",
        component: () => import("../views/ucenter/Follow.vue"),
        hidden: true,
        meta: {
          title: "我的关注",
        },
      },
      {
        path: "/ucenter/profile",
        name: "ucenterProfile",
        component: () => import("../views/ucenter/Profile.vue"),
        hidden: true,
        meta: {
          title: "个人资料",
        },
      },

      {
        path: "/ucenter/org",
        name: "ucenterOrg",
        component: () => import("../views/ucenter/Org.vue"),
        hidden: true,
        meta: {
          title: "机构认证",
        },
      },

      {
        path: "/ucenter/password",
        name: "ucenterPassword",
        component: () => import("../views/ucenter/Password.vue"),
        hidden: true,
        meta: {
          title: "修改密码",
        },
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
    hidden: true,
    meta: {
      title: "账号登录",
    },
  },
];
// 解决报错
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};
// replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch((err) => err);
};
const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach(async (to, from, next) => {
  console.log("to:", to);
  /*   const token = localStorage.getItem(globalConfig.tokenName.user);
  if (to.meta && to.meta.mustLogin && !token && to.path != "/mobile/login") {
    next({
      path: "/mobile/login",
      query: { redirect: to.fullPath },
    });
    return;
  } */
  const paths = to.path.split("/");
  let checkPath = "";
  if (paths.length > 0) {
    checkPath = paths[1];
  }
  if (
    /(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent) &&
    checkPath !== "mobile"
  ) {
    let rePath = "/mobile";

    if (to.meta.hasMobile) {
      rePath += to.fullPath;
    }
    console.log("rePath:", rePath);
    location.replace(rePath);
  } else {
    next();
  }
});
export default router;
