/*
 * @Descripttion: []
 * @Author: Iseven Monkey <iswoter@gmail.com>
 * @Date: 2021-08-01 08:37:43
 * @LastEditors: Iseven Monkey <iswoter@gmail.com>
 * @LastEditTime: 2021-10-26 18:46:34
 * @FilePath: /site/src/views/index/plugins/AxiosPlugin.js
 */

import Vue from "vue";
import AxiosBase from "@/plugins/Axios";
import utils from "@/plugins/utils";
import store from "../store";

const _option = {};

_option.getToken = () => {
  return store.getters.token;
};
//错误处理
_option.error = (res, cancelToken) => {
  console.log("res:", res.status, res.status === 401);
  if (typeof res == "string") {
    utils.error(res);
    return;
  } else {
    //登录失败
    if (res.status === 401 || res.status === 403) {
      if (cancelToken) {
        //终止所有请求
        cancelToken();
      }
      res.data.msg && utils.error(res.data.msg);
      store.dispatch("userLogout");
      return;
    }
  }

  if (res.msg) {
    utils.error(res.msg);
  }
};

_option.updateAuth = ({ token, user }) => {
  if (!token) {
    return;
  }
  store.dispatch("setUser", {
    token,
    user,
  });
};

_option.success = (res) => {
  if (!utils.isEmpty(res.data.systemSettings)) {
    store.commit("SET_SETTINGS", res.data.systemSettings);
  }
};

Plugin.install = (Vue, options) => {
  options = Object.assign({}, _option, options);
  Vue.axios = AxiosBase(options);
  window.axios = AxiosBase(options);
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return AxiosBase(options);
      },
    },
    $axios: {
      get() {
        return AxiosBase(options);
      },
    },
  });
};

Vue.use(Plugin);
export const Axios = AxiosBase(_option);
export default Plugin;
