/*
 * @Descripttion: []
 * @Author: Iseven Monkey <iswoter@gmail.com>
 * @Date: 2021-08-08 08:09:14
 * @LastEditors: Iseven Monkey <iswoter@gmail.com>
 * @LastEditTime: 2021-10-27 17:10:32
 * @FilePath: /site/src/views/index/api/modules/ucenter/Organization.js
 */

import { Axios } from "../../../plugins/AxiosPlugin";
export const detail = (params) =>
  Axios.get("/api/front/user/organization/detail", {
    params: params,
  });

export const certifiedLog = (params) =>
  Axios.get("/api/front/user/organization/certifiedLog", {
    params: params,
  });

export const certifiedLogSave = (params) =>
  Axios.post("/api/front/user/organization/certifiedLogSave", params);

export const logo = (params) =>
  Axios.post("/api/front/user/organization/logo", params);
