/*
 * @Descripttion: []
 * @Author: Iseven Monkey <iswoter@gmail.com>
 * @Date: 2021-06-25 16:43:50
 * @LastEditors: Iseven Monkey <iswoter@gmail.com>
 * @LastEditTime: 2021-09-13 19:42:48
 * @FilePath: /site/src/pages/index/store/getters.js
 */
const getters = {
  settings: (state) => state.app.settings,
  user: (state) => state.user.user,
  token: (state) => state.user.token,
  permission_routers: (state) => state.permission.routers,
  addRouters: (state) => state.permission.addRouters,
};
export default getters;
