/*
 * @Descripttion: []
 * @Author: Iseven Monkey <iswoter@gmail.com>
 * @Date: 2021-08-02 08:24:44
 * @LastEditors: Iseven Monkey <iswoter@gmail.com>
 * @LastEditTime: 2021-11-08 17:32:42
 * @FilePath: /site/src/views/index/mixins/index.js
 */

export default {
  data() {
    return {
      captcha: null,
    };
  },
  watch: {
    $route() {},
  },
  computed: {
    Settings() {
      return this.$store.getters.settings;
    },
    user() {
      return this.$store.getters.user;
    },
    role() {
      return this.$store.getters.user.role;
    },
  },
  mounted() {},
  methods: {},
};
