/*
 * @Descripttion: []
 * @Author: Iseven Monkey <iswoter@gmail.com>
 * @Date: 2021-08-01 17:34:23
 * @LastEditors: Iseven Monkey <iswoter@gmail.com>
 * @LastEditTime: 2021-10-27 09:38:56
 * @FilePath: /site/src/views/index/api/modules/vlog/Vlog.js
 */
import { Axios } from "../../../plugins/AxiosPlugin";

export const detail = (params) =>
  Axios.get("/api/front/vlog/vlog/detail", {
    params: params,
  });
