/*
 * @Descripttion: []
 * @Author: Iseven Monkey <iswoter@gmail.com>
 * @Date: 2021-08-01 17:34:23
 * @LastEditors: Iseven Monkey <iswoter@gmail.com>
 * @LastEditTime: 2021-08-08 09:38:33
 * @FilePath: /site/src/pages/mobile/api/modules/Upload.js
 */
import { Axios } from "../../plugins/AxiosPlugin";

export const upload = (params) => Axios.post("/api/front/upload", params);
