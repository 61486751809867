/*
 * @Descripttion: []
 * @Author: Iseven Monkey <iswoter@gmail.com>
 * @Date: 2021-08-01 17:34:23
 * @LastEditors: Iseven Monkey <iswoter@gmail.com>
 * @LastEditTime: 2021-10-21 19:54:16
 * @FilePath: /site/src/views/index/api/modules/help/Help.js
 */

import { Axios } from "../../../plugins/AxiosPlugin";
export const lists = (params) =>
  Axios.get("/api/front/help/help", {
    params: params,
  });

export const detail = (params) =>
  Axios.get("/api/front/help/help/detail", {
    params: params,
  });

export const save = (params) => Axios.post("/api/front/help/help/save", params);
export const del = (params) => Axios.post("/api/front/help/help/del", params);
