<template>
  <div class="layout">
    <Header @onsearch="search" />
    <div class="layout-guide">
      <div class="layout-guide-space"></div>
      <a href="javascript:;" @click="goHome">
        <img src="../assets/images/guide/home.png" />
        首页</a
      >
      <div class="divider"></div>
      <h3>浏览频道</h3>

      <a
        href="javascript:;"
        v-for="item in category"
        :key="item.id"
        @click="changeCategory(item)"
        :class="{ onactive: params.category_id == item.id }"
      >
        <img
          :src="
            item.icon
              ? viewImg(item.icon)
              : require('../assets/images/icon/dot.png')
          "
        />
        {{ item.name }}
      </a>
      <div class="divider"></div>
      <router-link to="/org" target="_blank">
        <img src="../assets/images/guide/org.png" />机构
      </router-link>
      <router-link to="/race" target="_blank">
        <img src="../assets/images/guide/race.png" />大赛
      </router-link>
      <div class="divider"></div>
      <router-link to="/help" target="_blank">
        <img src="../assets/images/guide/help.png" />帮助</router-link
      >
      <router-link to="/report" target="_blank">
        <img src="../assets/images/guide/feedback.png" />发送反馈</router-link
      >
      <router-link to="/contact" target="_blank">
        <img src="../assets/images/guide/contact.png" />联系我们</router-link
      >
      <div class="divider"></div>
      <a href="#">Copyright &copy; 2023 中咨数据有限公司 版权所有</a>
    </div>
    <div class="tagbar">
      <ul class="tag-list">
        <li @click="selectTag(0)" :class="{ active: !params.tag_id }">全部</li>
        <li
          :class="{ active: item.id == params.tag_id }"
          v-for="item in tags"
          :key="item.id"
          @click="selectTag(item.id)"
        >
          {{ item.name }}
        </li>
      </ul>
    </div>
    <div class="layout-container">
      <div class="list">
        <div class="list-item" v-for="item in lists" :key="'item' + item.id">
          <div class="list-item-body">
            <div v-if='item.is_auth === 1'>
              <div v-if='isLogin === 0'>
                <router-link :to="{ path: '/video/detail/' + item.id }">
                  <img
                    v-if="item.thumb"
                    :src="viewThumb(item.thumbData.thumb, 'medium')"
                    class="thumb"
                    @click.stop.prevent="InlineButtonClickHandler"
                  />
                  <h3>
                    <span @click.stop.prevent="InlineButtonClickHandler">{{ item.title }}</span>
                  </h3>              
                </router-link>
                <div
                class="
                  list-item-desc
                  d-flex
                  justify-content-between
                  align-items-center
                "
                @click.stop.prevent="InlineButtonClickHandler"
                >
                  <NickName v-model="item.user"></NickName>

                  <div>{{ $utils.contrastTime(item.create_time) }}</div>
                </div>
              </div>
              <div v-else>
                <router-link  :to="{ path: '/video/detail/' + item.id }">
                  <img
                    v-if="item.thumb"
                    :src="viewThumb(item.thumbData.thumb, 'medium')"
                    class="thumb"
                  />
                  <h3>
                    <span>{{ item.title }}</span>
                  </h3>              
                </router-link>
                <div
                  class="
                    list-item-desc
                    d-flex
                    justify-content-between
                    align-items-center
                  "
                  >
                    <NickName v-model="item.user"></NickName>

                    <div>{{ $utils.contrastTime(item.create_time) }}</div>
                </div>
              </div>
            </div>
            <div v-else>
              <router-link  :to="{ path: '/video/detail/' + item.id }">
                <img
                  v-if="item.thumb"
                  :src="viewThumb(item.thumbData.thumb, 'medium')"
                  class="thumb"
                />
                <h3>
                  <span>{{ item.title }}</span>
                </h3>              
              </router-link>
              <div
                class="
                  list-item-desc
                  d-flex
                  justify-content-between
                  align-items-center
                "
                >
                  <NickName v-model="item.user"></NickName>

                  <div>{{ $utils.contrastTime(item.create_time) }}</div>
                </div>
            </div>
          </div>
        </div>
      </div>

      <div class="text-center mt-20" v-if="params.pages > 1">
        <el-pagination
          @size-change="pageSizeChange"
          @current-change="pageChange"
          :current-page="params.page"
          :page-size="params.pageSize"
          layout="total, prev, pager, next, jumper"
          :total="params.total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "./layout/Header.vue";

export default {
  name: "Home",
  components: {
    Header,
  },
  data() {
    return {
      metaTitle: "",
      category: [], //频道
      tags: [], //标签
      loading: false,
      isLogin: 0,

      lists: [], //列表
      searchTimer: null, //搜索计时器
      params: {
        //查询参数
        is_publish: 0,
        category_id: "",
        tag_id: "",
        total: 0,
        page: 1,
        pageSize: this.$config.pageSize,
        keyword: "",
        pages: 1,
      },
    };
  },
  metaInfo() {
    const category_id = this.$utils.toInt(this.$route.query.category_id);
    if (category_id) {
      for (let index = 0; index < this.category.length; index++) {
        const element = this.category[index];
        if (element.id == category_id) {
          this.metaTitle = element.name + "-";
          break;
        }
      }
    }
    return {
      title:
        this.metaTitle + (this.Settings.base.name || this.$route.meta.title),
    };
  },
  watch: {
    "params.keyword": {
      handler: function () {
        if (this.searchTimer) {
          clearTimeout(this.searchTimer);
        }
        this.searchTimer = setTimeout(() => {
          this.getList();
        }, 1000);
      },
    },
  },
  created() {
    //频道
    this.$api.video.Category.lists().then((res) => {
      this.category = res;
    });
    //标签
    this.$api.tags.Tags.lists().then((res) => {
      this.tags = res;
    });
  },
  mounted() {
    this.params.category_id = this.$utils.toInt(this.$route.query.category_id);
    this.params.tag_id = this.$utils.toInt(this.$route.query.tag_id);
    this.getList();
  },
  methods: {
    selectTag(id) {
      /*   this.params.tag_id = id;
      this.getList(); */
      let url = "/?tag_id=" + id;
      const category_id = this.$utils.toInt(this.$route.query.category_id);
      if (this.params.category_id) {
        url += "&category_id=" + category_id;
      }
      location.replace(url);
    },
    //切换频
    changeCategory(item) {
      /*   this.params.category_id = item.id;
      this.metaTitle = item.name + "-";
      this.getList(); */
      let url = "/?category_id=" + item.id;
      const tag_id = this.$utils.toInt(this.$route.query.tag_id);
      if (this.params.tag_id) {
        url += "&tag_id=" + tag_id;
      }
      location.replace(url);
    },
    //取得视频列表
    getList(page) {
      this.params.page = this.$utils.toInt(page) || 1;
      this.loading = true;
      this.$api.video.Video.lists(this.params)
        .then((res) => {
          console.log("getList res=="+JSON.stringify(res));
          this.loading = false;
          this.lists = res.data.lists;
          this.isLogin = res.data.isLogin;
          this.params.total = res.data.total;
          this.params.pages = res.data.pages;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    search(keyword) {
      console.log("keyword:", keyword);
      this.params.keyword = keyword;
      this.getList();
    },
    // 分页点击
    pageChange(page) {
      this.getList(page);
    },
    // 点击分页大小
    pageSizeChange(pageSize) {
      this.params.pageSize = pageSize;
      this.getList(this.page);
    },
    goHome() {
      location.replace("/");
    },
    InlineButtonClickHandler(event) {
      event.preventDefault();
      alert("请登录再访问此视频！");
    },      
  },
};
</script>

<style lang="scss" scoped>
.tagbar {
  position: fixed;
  display: flex;
  align-items: center;
  top: 56px;
  left: 240px;
  z-index: 99;
  height: 56px;
  padding: 0 16px;

  background-color: rgba($color: #ffff, $alpha: 0.9);
  border-top: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
  width: calc(100% - 240px);
}
.layout-container {
  min-height: calc(100vh - 114px);
  margin-top: 114px;
}
</style>