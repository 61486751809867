/*
 * @Descripttion: [频道]
 * @Author: Iseven Monkey <iswoter@gmail.com>
 * @Date: 2021-08-01 17:34:23
 * @LastEditors: Iseven Monkey <iswoter@gmail.com>
 * @LastEditTime: 2021-10-19 10:37:51
 * @FilePath: /site/src/views/index/api/modules/video/Category.js
 */
import { Axios } from "../../../plugins/AxiosPlugin";

export const lists = async (params) => {
  const res = await Axios.get("/api/front/video/category", {
    params: params,
  });

  return res.data;
};

export const detail = (params) =>
  Axios.get("/api/front/video/category/detail", {
    params: params,
  });
