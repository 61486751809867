<template>
  <div class="layout-header" :class="{ shadow: hasShadow && scrollTop > 30 }">
    <div class="brand">
      <!--   <button>
        <img src="../../assets/images/guide.png" />
      </button> -->
      <router-link to="/">
        <img src="../../assets/images/logo.png" />
      </router-link>
    </div>
    <div>
      <div class="search-form" v-if="hasSearch">
        <input
          type="text"
          placeholder="搜索"
          v-model="keyword"
          name="keywords"
          value=""
        />
        <button>
          <i class="el-icon-search" aria-hidden="true"></i>
        </button>
      </div>
    </div>
    <div class="d-flex align-items-center">
      <template v-if="user">
        <a href="javascript:;" @click="publish" class="mr-20">
          <el-tooltip effect="dark" content="发布视频" placement="bottom">
            <img
              src="../../assets/images/icon/video_add.png"
              alt=""
            /> </el-tooltip
        ></a>

        <el-dropdown @command="rightMenuCommand">
          <el-avatar
            size="small"
            :src="
              user.avatar
                ? viewImg(user.avatar)
                : require('../../assets/images/userAvatar.png')
            "
          ></el-avatar>
          <el-dropdown-menu slot="dropdown" class="right-menu">
            <el-dropdown-item class="text-bold h5">
              <div>
                Hi,<NickName v-model="user" :showAvatar="false"></NickName>
                <div v-if="user.role" class="w-100 small text-primary">
                  {{ user.role.name }}
                </div>
              </div>
            </el-dropdown-item>
            <el-dropdown-item command="/ucenter/publish">
              <img src="../../assets/images/icon/video_add.png" alt="" />
              发布视频
            </el-dropdown-item>
            <el-dropdown-item command="/ucenter/video">
              <img src="../../assets/images/icon/video.png" alt="" />作品管理
            </el-dropdown-item>
            <el-dropdown-item command="/ucenter/comment">
              <img src="../../assets/images/icon/comment.png" alt="" />评论管理
            </el-dropdown-item>
            <el-dropdown-item command="/ucenter/org">
              <img src="../../assets/images/icon/org.png" alt="" />{{
                user.org ? "机构信息" : "机构认证"
              }}
            </el-dropdown-item>

            <el-dropdown-item command="/ucenter/follow">
              <img src="../../assets/images/icon/follow.png" alt="" />我的关注
            </el-dropdown-item>
            <el-dropdown-item command="/ucenter/fav">
              <img src="../../assets/images/icon/fav.png" alt="" />我的收藏
            </el-dropdown-item>
            <el-dropdown-item command="/ucenter/profile">
              <img src="../../assets/images/icon/profile.png" alt="" />个人资料
            </el-dropdown-item>
            <el-dropdown-item command="/ucenter/password">
              <img src="../../assets/images/icon/password.png" alt="" />修改密码
            </el-dropdown-item>
            <el-dropdown-item command="logout">
              <img
                src="../../assets/images/icon/logout.png"
                alt=""
              />退出登录</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </template>
      <template v-else>
        <el-button
          type="default"
          size="small"
          @click="$router.push({ path: '/login' })"
          >登录</el-button
        >
        <el-button
          type="default"
          size="small"
          @click="
            $router.push({
              path: '/login',
              query: {
                tab: 'signup',
              },
            })
          "
          >注册</el-button
        >
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: {
    hasShadow: {
      type: Boolean,
      default: false,
    },
    hasSearch: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      scrollTop: 0,
      searchTimer: null,
      keyword: "",
    };
  },
  watch: {
    keyword(newValue) {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
      }
      this.searchTimer = setTimeout(() => {
        console.log("newValue:", newValue);
        this.$emit("onsearch", newValue);
      }, 1000);
    },
  },
  mounted() {
    this.scrollTop =
      document.body.scrollTop || document.documentElement.scrollTop;
    window.addEventListener(
      "scroll",
      (e) => {
        this.scrollTop =
          document.body.scrollTop || document.documentElement.scrollTop;
      },
      true
    );
  },
  methods: {
    rightMenuCommand(c) {
      console.log("c:", c);
      if (c == "logout") {
        this.$store.dispatch("userLogout");
        return;
      }
      if (c == "/ucenter/publish") {
        this.publish();
        return;
      }
      if (c) {
        this.$router.push(c);
      }
    },
    publish() {
      location.replace("/ucenter/publish");
    },
  },
};
</script>

<style lang="scss" scoped>
.right-menu {
  li {
    display: flex;
    justify-content: start;
    align-items: center;
    img {
      width: 24px;
      height: 24px;
      margin-right: 16px;
    }
  }
}
</style>