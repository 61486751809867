/*
 * @Descripttion: [评论]
 * @Author: Iseven Monkey <iswoter@gmail.com>
 * @Date: 2021-08-01 17:34:23
 * @LastEditors: Iseven Monkey <iswoter@gmail.com>
 * @LastEditTime: 2021-10-20 23:16:27
 * @FilePath: /site/src/views/index/api/modules/video/Comment.js
 */

import { Axios } from "../../../plugins/AxiosPlugin";
export const lists = (params) =>
  Axios.get("/api/front/video/comment", {
    params: params,
  });

export const detail = (params) =>
  Axios.get("/api/front/video/comment/detail", {
    params: params,
  });

export const save = (params) =>
  Axios.post("/api/front/video/comment/save", params);

export const del = (params) =>
  Axios.post("/api/front/video/comment/del", params);

export const delByFor = (params) =>
  Axios.post("/api/front/video/comment/delByFor", params);

export const fav = (params) =>
  Axios.post("/api/front/video/comment/fav", params);

export const unfav = (params) =>
  Axios.post("/api/front/video/comment/unfav", params);
