/*
 * @Descripttion: []
 * @Author: Iseven Monkey <iswoter@gmail.com>
 * @Date: 2021-08-01 17:34:23
 * @LastEditors: Iseven Monkey <iswoter@gmail.com>
 * @LastEditTime: 2021-08-08 09:35:38
 * @FilePath: /site/src/pages/mobile/api/modules/article/Article.js
 */

import { Axios } from "../../../plugins/AxiosPlugin";
export const lists = (params) =>
  Axios.get("/api/front/article/article", {
    params: params,
  });

export const detail = (params) =>
  Axios.get("/api/front/article/article/detail", {
    params: params,
  });

export const save = (params) =>
  Axios.post("/api/front/article/article/save", params);
export const del = (params) =>
  Axios.post("/api/front/article/article/del", params);
