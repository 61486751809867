/*
 * @Descripttion: []
 * @Author: Iseven Monkey <iswoter@gmail.com>
 * @Date: 2021-05-21 23:52:05
 * @LastEditors: Iseven Monkey <iswoter@gmail.com>
 * @LastEditTime: 2021-10-27 12:10:29
 * @FilePath: /site/src/views/index/main.js
 */
import Vue from "vue";
import App from "./App.vue";
import "@/registerServiceWorker";
import router from "./router";
import store from "./store";
import config from "@/config";
import utils from "@/plugins/utils";

import bus from "@/plugins/Bus";
import MetaInfo from "vue-meta-info";
import "@/plugins/ElementUI";

import "./assets/scss/home.scss";
import Pagination from "@/components/pagination";
import Icon from "@/components/icon";
Vue.component("icon", Icon);
import NickName from "@/components/NickName";
Vue.component("NickName", NickName);
import VueVideoPlayer from "vue-video-player";

// require videojs style
import "video.js/dist/video-js.css";
// import 'vue-video-player/src/custom-theme.css'

Vue.use(
  VueVideoPlayer /* {
  options: global default options,
  events: global videojs events
} */
);

import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);

Vue.config.productionTip = false;
Vue.prototype.$config = config;
Vue.prototype.$utils = utils;
Vue.prototype.$bus = bus;

import Axios from "./plugins/AxiosPlugin";
Vue.use(Axios);

import api from "./api";
Vue.prototype.$api = api;

import globalMixins from "@/mixins";
import mixins from "./mixins";
Vue.use(MetaInfo);
Vue.use(Pagination);

import dataV from "@jiaminghi/data-view";
Vue.use(dataV);

api.system.Settings.lists().then((res) => {
  store.commit("SET_SETTINGS", res.data);
  Vue.mixin(globalMixins);
  Vue.mixin(mixins);
  new Vue({
    router,
    store,

    render: (h) => h(App),
  }).$mount("#app");
});
