/*
 * @Descripttion: []
 * @Author: Iseven Monkey <iswoter@gmail.com>
 * @Date: 2021-08-01 17:34:23
 * @LastEditors: Iseven Monkey <iswoter@gmail.com>
 * @LastEditTime: 2021-10-24 07:19:12
 * @FilePath: /site/src/views/index/api/modules/race/Race.js
 */
import { Axios } from "../../../plugins/AxiosPlugin";

export const lists = async (params) => {
  const res = await Axios.get("/api/front/race/race", {
    params: params,
  });

  return res;
};

export const detail = (params) =>
  Axios.get("/api/front/race/race/detail", {
    params: params,
  });
