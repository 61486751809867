/*
 * @Descripttion: []
 * @Author: Iseven Monkey <iswoter@gmail.com>
 * @Date: 2021-08-01 17:34:23
 * @LastEditors: Iseven Monkey <iswoter@gmail.com>
 * @LastEditTime: 2021-08-21 21:19:50
 * @FilePath: /site/src/pages/mobile/api/modules/article/Category.js
 */
import { Axios } from "../../../plugins/AxiosPlugin";

export const lists = async (params) => {
  const res = await Axios.get("/api/front/article/category", {
    params: params,
  });

  const types = res.data;
  let childrenTypes = [];
  types.forEach((item) => {
    item.children = [];
    types.forEach((sub) => {
      if (sub.parent_id == item.id) {
        item.children.push(sub);
      }
    });
    childrenTypes.push(item);
  });
  let resTypes = [];
  childrenTypes.forEach((item) => {
    if (!item.parent_id) {
      resTypes.push(item);
    }
  });
  return {
    lists: resTypes,
    total: types.length,
  };
};

export const detail = (params) =>
  Axios.get("/api/front/article/category/detail", {
    params: params,
  });
