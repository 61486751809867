/*
 * @Descripttion: []
 * @Author: Iseven Monkey <iswoter@gmail.com>
 * @Date: 2021-08-01 13:51:53
 * @LastEditors: Iseven Monkey <iswoter@gmail.com>
 * @LastEditTime: 2021-10-21 09:57:55
 * @FilePath: /site/src/views/index/store/modules/user.js
 */
import router from "../../router";

const tokenStorageName = "token"; //token储存名称
const userStorageName = "user"; //用户信息储存名称
const _token = localStorage.getItem(tokenStorageName);
const _user = localStorage.getItem(userStorageName);
export const state = {
  token: _token ? _token : null,
  user: _user ? JSON.parse(_user) : null,
};

// mutations
export const mutations = {
  SET_USER: (state, data) => {
    state.user = data;
    localStorage.setItem(userStorageName, JSON.stringify(data));
  },

  SET_USER_TOKEN: (state, data) => {
    state.token = data;
    localStorage.setItem(tokenStorageName, data);
  },

  DEL_USER: (state) => {
    state.user = null;
    localStorage.removeItem(userStorageName);
  },
  DEL_USER_TOKEN: (state) => {
    state.token = null;
    localStorage.removeItem(tokenStorageName);
  },
};

// actions
export const actions = {
  setUser({ commit }, params) {
    commit("SET_USER", params.user);
    commit("SET_USER_TOKEN", params.token);
  },
  setUserInfo({ commit }, user) {
    commit("SET_USER", user);
  },
  userLogout({ commit }) {
    commit("DEL_USER");
    commit("DEL_USER_TOKEN");
    let redirect = window.location.pathname + window.location.search;
    router.push({ path: "/login", query: { redirect: redirect } });
  },
  delUser({ commit }) {
    commit("DEL_USER");
    commit("DEL_USER_TOKEN");
  },
};
