/*
 * @Descripttion: []
 * @Author: Iseven Monkey <iswoter@gmail.com>
 * @Date: 2021-08-04 17:21:54
 * @LastEditors: Iseven Monkey <iswoter@gmail.com>
 * @LastEditTime: 2021-09-13 20:12:56
 * @FilePath: /site/src/pages/index/api/modules/system/Settings.js
 */
import { Axios } from "../../../plugins/AxiosPlugin";
export const lists = (params) =>
  Axios.get("/api/front/system/settings", {
    params: params,
  });
export const detail = (params) =>
  Axios.get("/api/front/system/settings/detail", {
    params: params,
  });
