/*
 * @Descripttion: [评论]
 * @Author: Iseven Monkey <iswoter@gmail.com>
 * @Date: 2021-08-01 17:34:23
 * @LastEditors: Iseven Monkey <iswoter@gmail.com>
 * @LastEditTime: 2021-10-27 11:17:43
 * @FilePath: /site/src/views/index/api/modules/follow/Follow.js
 */

import { Axios } from "../../../plugins/AxiosPlugin";
export const lists = (params) =>
  Axios.get("/api/front/follow/follow", {
    params: params,
  });

export const save = (params) =>
  Axios.post("/api/front/follow/follow/save", params);

export const del = (params) =>
Axios.post("/api/front/follow/follow/del", params);
