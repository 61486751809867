/*
 * @Descripttion: []
 * @Author: Iseven Monkey <iswoter@gmail.com>
 * @Date: 2021-08-01 13:51:53
 * @LastEditors: Iseven Monkey <iswoter@gmail.com>
 * @LastEditTime: 2021-08-01 16:51:06
 * @FilePath: /site/src/pages/console/store/index.js
 */
import Vue from "vue";
import Vuex from "vuex";
import app from "./modules/app";
import * as user from "./modules/user";
import getters from "./getters";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    app,
    user,
  },
  getters,
});

export default store;
