<template>
  <div class="layout">
    <Header />
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
import Header from "./Header.vue";
export default {
  name: "Layout",
  components: {
    Header,
  },
};
</script>

<style lang="scss" scoped>
</style>