/*
 * @Descripttion: []
 * @Author: Iseven Monkey <iswoter@gmail.com>
 * @Date: 2021-08-01 07:36:40
 * @LastEditors: Iseven Monkey <iswoter@gmail.com>
 * @LastEditTime: 2021-08-21 21:19:44
 * @FilePath: /site/src/pages/mobile/api/index.js
 */

const modulesFiles = require.context("./modules", true, /\.js$/);
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, "$1");

  const value = modulesFiles(modulePath);
  const moduleNameSplit = moduleName.split("/");
  if (moduleNameSplit.length > 1) {
    if (!modules[moduleNameSplit[0]]) {
      modules[moduleNameSplit[0]] = {};
    }

    if (moduleNameSplit[2]) {
      if (!modules[moduleNameSplit[0]][moduleNameSplit[1]]) {
        modules[moduleNameSplit[0]][moduleNameSplit[1]] = {};
      }

      modules[moduleNameSplit[0]][moduleNameSplit[1]][moduleNameSplit[2]] =
        value;
    } else {
      modules[moduleNameSplit[0]][moduleNameSplit[1]] = value;
    }
  } else {
    modules[moduleName] = value;
  }

  return modules;
}, {});
export default modules;
