/*
 * @Descripttion: []
 * @Author: Iseven Monkey <iswoter@gmail.com>
 * @Date: 2021-08-01 13:51:53
 * @LastEditors: Iseven Monkey <iswoter@gmail.com>
 * @LastEditTime: 2021-10-16 08:13:51
 * @FilePath: /site/src/views/index/store/modules/app.js
 */

const app = {
  state: {
    settings: {},
  },
  mutations: {
    SET_SETTINGS: (state, settings) => {
      state.settings = settings || {};
    },
  },
  actions: {
    setSettings({ commit }, settings) {
      commit("SET_SETTINGS", settings);
    },
  },
};

export default app;
