/*
 * @Descripttion: []
 * @Author: Iseven Monkey <iswoter@gmail.com>
 * @Date: 2021-08-01 07:34:15
 * @LastEditors: Iseven Monkey <iswoter@gmail.com>
 * @LastEditTime: 2021-10-16 13:46:55
 * @FilePath: /site/src/views/index/api/modules/Auth.js
 */

import { Axios } from "../../plugins/AxiosPlugin";
export const login = (params) => Axios.post("/api/front/auth/login", params);
export const create = (params) => Axios.post("/api/front/auth/create", params);
export const find = (params) => Axios.post("/api/front/auth/find", params);
export const createByMobile = (params) =>
  Axios.post("/api/front/auth/createByMobile", params);
